import './Loader.style.scss';
import './LoaderImage.style.scss';
// import backgroundImage from '../../Server/1624980310310.jpeg'; 
{/* <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> */}
const Loader = ({userSnapshot}) => {
    console.log(userSnapshot);
    if (!userSnapshot) return <div></div>;
    return (
    <div className="loadingImageContainer">
        <img className="loadingImage" src={`HOME/uploads/${userSnapshot}`}></img>
    </div>
    )
}

export default Loader;