import React, { useEffect, useState } from "react";
import styled, { css } from 'styled-components'
import { useMediaQuery } from 'react-responsive';

import './Background.style.scss';

// import '../../Server/filenames.json';


const Background = ({userSnapshot}) => {
  console.log(userSnapshot)

    // const [backgroundImg, setBackgroundImg] = useState(null);
    // const [bgImgComponent, setBgImgComponent] = useState(null);

// Find a different way to pass Image, not styled component
// useEffect(() => {

//   setBgImgComponent(BackgroundImage)
// },[backgroundImg]);
if (!userSnapshot) return <div></div>;
let BgImgComponent = styled.div`
position: fixed;
width:100vw;
height:100vh;
z-index: 0;
transform:rotateY(180deg);
background-size: cover;
background-position: center;
background-repeat: no-repeat;
background-image: url(HOME/uploads/${userSnapshot})
`;


//   if (!userSnapshot) return <div>Loading...</div>;
// console.log(userSnapshot);
  return (
    <BgImgComponent />
  );
}

export default Background;