import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import BlockContent from "@sanity/block-content-to-react";
import sanityClient from "../../client.js";

// import moment from 'moment';
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import './About.style.scss'

const About = () => { 

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-width: 1000px)'
      });

      const [postData, setPostData] = useState(null);

      useEffect(() => {
      
        sanityClient
        .fetch(
          `*[_id == "about"]{
              aboutText
            }[0]
          `
        )
        .then((data) => setPostData(data))
        .catch(console.error);
          
      }, []);

//   if (!postData) return <div>Loading...</div>;


if (!postData) return <></>;
console.log(postData.aboutText)
  return (
    <div className="about">

        <header className="top">

        <div className="left container">

        </div>
        <div className="right container">
          {/* <ProjectSlideCounter /> */}
        </div>

        </header>

        <div className='projectInfo'>
            {isTabletOrMobileDevice ? 
              <div className="mobile-back">
                <Link to={'/'}>Back</Link>
              </div>
              :
              ''
            }
            <div className="text">
              <BlockContent
              blocks={postData.aboutText}
              projectId={sanityClient.clientConfig.projectId}
              dataset={sanityClient.clientConfig.dataset}
              />
            </div>
            {/* <div className="text">
            
              <div className="subtext">
                <div className="subtextTitle">Selected solo shows</div>
                <BlockContent
                blocks={postData.subtext1}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
                />
              </div>
              <div className="subtext">
              <div className="subtextTitle">Selected group shows</div>
                <BlockContent
                blocks={postData.subtext2}
                projectId={sanityClient.clientConfig.projectId}
                dataset={sanityClient.clientConfig.dataset}
                />
              </div>
            </div> */}
        </div>


      <footer className="bottom">
          <div className="left container">
          </div>
          <div className="right container">
            {isTabletOrMobileDevice ? 
            ''
            :
            <Link to={'/Browse'}>Work</Link>
            }
            
          </div>

      </footer>

    </div>
  );
}

export default About;