import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";
import { useHistory } from "react-router-dom";

// import moment from 'moment';
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import "./Photobooth.style.scss";

const Photobooth = () => {
  let history = useHistory();

  const webcamRef = useRef(null);
  let canvasRef = useRef(null);
  let resultRef = useRef(null);
  let imageRef = useRef(null);

  const [flashActive, setFlashActive] = useState(null);
  const [resultImgSrc, setResultImgSrc] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  // let [canvas, setCanvas] = useState(null)
  // let [context, setContext] = useState(null)

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 1000px)",
  });

  useEffect(() => {
    canvasRef.current.getContext("2d");
    // setCanvas(canvasRef.current);
    // const canvas = canvasRef.current;
    // setContext(canvas.getContext('2d'));
  }, []);

  // useEffect(() =>{

  //   var constraints = {
  //     audio: false,
  //     video: true
  //   };

  //   function handleSuccess(stream) {
  //     window.stream = stream;
  //     videoRef.srcObject = stream;

  //     if(stream.getVideoTracks().length > 0){
  //       console.log('video!');
  //     }else{
  //       console.log('no video!');
  //       // code for when both devices are available
  //     }
  //   }

  //   function handleError(error) {
  //     console.log('navigator.getUserMedia error: ', error);
  //       // displayPhotoboothError();
  //       // window.setTimeout(function(){
  //       //   exitPhotobooth();
  //       // },2000)
  //   }

  //   navigator.mediaDevices
  //     .getUserMedia(constraints)
  //     .then(handleSuccess)
  //     .catch(handleError);

  // },[context])

  const displayPhotoboothError = (err) => {
    setErrorMsg(err);
  };

  const uploadImage = () => {
    // canvasRef.width = window.innerWidth;
    // canvasRef.height = window.innerHeight;
    // console.log(data);
    // let strippedString = data.replace('data:image/webp;base64,','');

    // let currentSource = imageRef.current.src;
    // console.log(resultImgSrc);
    // let strippedString = currentSource.replace('data:image/jpeg;base64,','');
    // console.log(strippedString);

    // canvasRef.current.getContext('2d').drawImage(resultImgSrc, 0, 0, canvasRef.width, canvasRef.height);
    // let canvasData = canvasRef.toDataURL("image/jpeg");
    // console.log(canvasData);
    // let imgData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);

    let uploadImgNameDate = new Date();
    let uploadImgName = uploadImgNameDate.getTime() + ".jpeg";

    let MyDate = new Date();
    let MyDateString;
    MyDate.setDate(MyDate.getDate());
    MyDateString =
      ("0" + MyDate.getDate()).slice(-2) +
      "." +
      ("0" + (MyDate.getMonth() + 1)).slice(-2);
    let uploadImgDate = MyDateString;

    fetch("https://www.jonasersland.net/HOME/upload.php", {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        imgBase64: resultImgSrc,
        imgName: uploadImgName,
        imgDate: uploadImgDate,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status == "ok") {
          // console.log("All ok");
          window.location.assign("https://www.jonasersland.net");
        }
        if (responseData.status == "error") {
          displayPhotoboothError(
            "Your browser does not seem to support this feature – sorry about that!"
          );
        }
      });

    // console.log('uploaded', data);
  };

  const cheeseHandler = () => {
    // console.log(webcamRef.current);
    setFlashActive("flash");
    const result = webcamRef.current.getScreenshot();
    setResultImgSrc(result);
    //uploadImage(result);
  };

  const submitImageHandler = () => {
    uploadImage(resultImgSrc);
  };

  const tryAgainHandler = () => {
    window.location.reload();
  };

  // useEffect(() => {

  // }, []);

  //   if (!postData) return <div>Loading...</div>;

  return (
    <div className="photobooth">
      {errorMsg ? <div className="errorMessageContainer">{errorMsg}</div> : ""}

      {!isTabletOrMobileDevice ? (
        <header className="top">
          <div className="left container">
            <Link to={"/"} className="linkLike">
              Back
            </Link>
          </div>
          <div className="right container">
            {resultImgSrc ? (
              <div>
                <div className="pointer" onClick={() => submitImageHandler()}>
                  {/* <span className='cam'>
                    💌
                    </span> */}
                  <span className="linkLike">Submit!</span>
                </div>
                <div className="pointer" onClick={() => tryAgainHandler()}>
                  {/* <span className='cam'>
                      📸
                    </span> */}
                  <span className="linkLike">Try again</span>
                </div>
              </div>
            ) : (
              <div className="pointer" onClick={() => cheeseHandler()}>
                {/* <span className='cam'>📸</span> */}
                <span className="linkLike">Take photo</span>
              </div>
            )}
          </div>
        </header>
      ) : (
        <div className="top">
          <div className="photobooth-left">
            <Link to={"/"} className="linkLike padding-top">
              Back
            </Link>
          </div>

          <div className="photobooth-right">
            {resultImgSrc ? (
              <div>
                <div
                  className="photobooth-btn"
                  onClick={() => submitImageHandler()}
                >
                  Submit!
                </div>
                <div
                  className="photobooth-btn"
                  onClick={() => tryAgainHandler()}
                >
                  Try again
                </div>
              </div>
            ) : (
              <div className="photobooth-btn" onClick={() => cheeseHandler()}>
                Take photo
              </div>
            )}
          </div>
        </div>
      )}
      <div ref={resultRef} id="visitorSnapPhoto">
        <div className={`flashContainer ${flashActive}`}></div>
        {resultImgSrc && <img ref={imageRef} src={resultImgSrc} />}
      </div>

      <div id="webCamWrapper">
        <Webcam ref={webcamRef} className="webcam" />
      </div>

      {/* <video ref={videoRef} id="visitorSnapPreview" autoPlay muted playsInline></video>*/}

      <canvas ref={canvasRef} id="visitorSnapPreviewCanvas"></canvas>
    </div>
  );
};

export default Photobooth;
