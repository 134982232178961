import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import BlockContent from "@sanity/block-content-to-react";

import SanityMuxPlayer from 'sanity-mux-player'

import sanityClient from "../../client.js";

import { animated, useSpring } from 'react-spring';

import './Browse.style.scss';

// import moment from 'moment

const Video = () => { 

  const isTabletOrMobileDevice = useMediaQuery({
      query: '(max-width: 1000px)'
    });

  const [browseFilter, setBrowseFilter] = useState('visualProject');
  const [postData, setPostData] = useState(null);

  const [currentSlideBrowseIndex, setCurrentSlideBrowseIndex] = useState(0);
  const [currentSlideProjectIndex, setCurrentSlideProjectIndex] = useState(0);
  const [isSlideMovingRight, setSlideMovingRight] = useState(true);

  // const [displayProjectToggled, toggleDisplayProject] = useState(false);
  const [displayProjectInfoToggled,toggleDisplayProjectInfo] = useState(false);
  const [videoPlaybackSize, setVideoPlaybackSize] = useState(null)

  const isMounted = useRef(false);

useEffect(() => {
    //if (isMounted.current) { // will not run on mount
//   const windowHeight = (window.innerHeight - 200);
//   const windowWidth = window.innerHeight;
//   setVideoPlaybackSize({height: windowHeight, width: windowWidth});

  // fetch all the projects

  sanityClient
  .fetch(
    `*[_id == "2f5b1473-7af4-4cc4-8f9e-1aad9b49fc50"]{
        projectTitle,
        projectText,
        slug,
        projectAssets[]{'isImage': isImage, "imageUrl": image.asset->url, 'muxAssetDoc': *[_id == ^.video.asset._ref]{...} }
      }
    `
  )
  .then((data) => setPostData(data))
  .catch(console.error);
//     }else{
//     isMounted.current = true;
// }
}, []);

// useEffect(() => {

// console.log('browseSlide', currentSlideBrowseIndex);
// console.log('projectSlide', currentSlideProjectIndex);

// }, [currentSlideBrowseIndex, currentSlideProjectIndex]);

// const slideDisplayProps = useSpring({
//   from: {opacity:0, transform: isSlideMovingRight ? 'translatex(20px)' : 'translatex(-20px)'},
//   to: {opacity:1, transform: 'translatex(0px)'},
//   config:{duration:100},
//   reset: true
// })

// const slideDisplayProps = useSpring({
//   opacity: 1,
//   from: { opacity: 0 },
//   config:{duration:100},
// })

// const projectSlideDisplayProps = useSpring({
//   from: {opacity:0, transform: 'translatey(-20px)'},
//   to: {opacity:1, transform: 'translatex(0px)'},
//   config:{duration:100},
//   reset: true
// })

// const springProps = useSpring({
//   transform: zoomCoordinates ? `translate(${zoomCoordinates.x}px, ${zoomCoordinates.y}px) scale(${zoomCoordinates.s})` : `translate(0px, 0px) scale(0.25)`,
//   onRest: () => (setNavigationDirection(false))
// })

// const handleClose = () =>{
//   if(displayProjectInfoToggled) {toggleDisplayProjectInfo(false);}
//   // if(displayProjectToggled) {toggleDisplayProject(false);}
// }

// const handleSlideClick = () =>{

//   const shiftProjectSlideIndex = () =>{

//       let comingSlideIndex = currentSlideProjectIndex + 1;

//       if(comingSlideIndex > (postData[currentSlideBrowseIndex].projectAssets.length - 1)){
//         comingSlideIndex = 0
//       }
//       if(comingSlideIndex < 0){
//         comingSlideIndex = (postData[currentSlideBrowseIndex].projectAssets.length - 1);
//       }
//       console.log(comingSlideIndex)
//       setCurrentSlideProjectIndex(comingSlideIndex);
//   }
//   shiftProjectSlideIndex();
// }

// const shiftBrowseSlideIndex = (i) =>{
//   setCurrentSlideProjectIndex(0);
//   let isSlideMovingRight = i == 1; //if the slide factor is positive, slideMovingRight is true

//     let comingSlideIndex = currentSlideBrowseIndex + i;
//     if(comingSlideIndex > (postData.length - 1)){
//       comingSlideIndex = 0
//     }
//     if(comingSlideIndex < 0){
//       comingSlideIndex = (postData.length - 1);
//       isSlideMovingRight = false;
//     }
//     setSlideMovingRight(isSlideMovingRight);
//     setCurrentSlideBrowseIndex(comingSlideIndex);

// }

// const returnImageHeight = () =>{
//   return `?h=700`;
// }

// const CurrentSlide = () =>{
//   console.log(postData[currentSlideBrowseIndex].projectImages[currentSlideProjectIndex].imageUrl);
//   return (
//     <div>
//       <img src={`${postData[currentSlideBrowseIndex].projectImages[currentSlideProjectIndex].imageUrl}${returnImageHeight()}`}/>
//       {/* <img src={postData[currentSlideBrowseIndex].projectImages[currentSlideProjectIndex].imageUrl}/> */}
//     </div>
//   )
// }

          /* {postData[0][currentSlideProjectIndex].map((post,index) =>{
            return <animated.div
              style={projectSlideDisplayProps}
              key={index}
              slideindex={index}
              onClick={() => handleClose()}
              className={(index == currentSlideProjectIndex) ? 'display carouselImageWrapper' : 'carouselImageWrapper'}>
                <img src={post.image} />
              </animated.div>
          })} */

// const DisplayProject = () =>{ //click the image to scroll through the series
//   return (
//     <div>
//           {individualImages.map((post,index) =>{
//             return <animated.div
//               style={projectSlideDisplayProps}
//               key={index}
//               slideindex={index}
//               onClick={() => handleClose()}
//               className={(index == currentSlideProjectIndex) ? 'display carouselImageWrapper' : 'carouselImageWrapper'}>
//                 <img src={post.image} />
//               </animated.div>
//           })}
//     </div>
//   )
// }



// useEffect(() => { 
//   if (isMounted.current) { // will not run on mount
//   let fetchRequest = `*[_id == "${postToFetch.id}"]{
//           _id,
//           title,
//           _type,
//           text,
//           images,
//           videos[]{
//               'muxAssetDoc': *[_id == ^.video.asset._ref]{...},
//               'youtube': {...},
//               'vimeo': {...}
//           },
//           referenceType,
//           "tags": referenceTags[]{"id": *[_type == "tagItem" && _id == ^._ref]{_id, title}, "count": count(*[_type == "referenceItem" && references(^._ref) || _type == "project" && references(^._ref)])},
//           "tagged": *[_type == "referenceItem" && references(^._id) || _type == "project" && references(^._id)]{ title,_id,_type }
//           }
//           `;
//   sanityClient
//       .fetch(fetchRequest)
//       .then((data) => setPostData(() => {
//           let flatArray;

//               if (postToFetch.offset === 0){ // if no unique index given
//                   flatArray = [].concat.apply([], [...postData, ...[data[0]]]);
//               }
//               else{ // if it has an index offset
//                   let tempArray = [...postData];
//                   tempArray[0][tempArray[0].length - postToFetch.offset] = data[0];
//                   flatArray = [].concat.apply([],[...tempArray]);
//               }

//               console.log('flatArray', flatArray)
//               return [
//                   flatArray
//               ];
//         })) 
//       .catch(console.error);
//   }
//   else{
//       isMounted.current = true;
//   }
// }, [postToFetch]); 

// const ProjectSlideCounter = () =>{
//   return <ul>
//     {postData[currentSlideBrowseIndex].projectImages.map((post,index) =>{
//       return <li
//               key={index}
//               >
// index
//       </li>
//     })}
//   </ul>
// }

  if (!postData) return <div>Loading...</div>;
//console.log(postData);
  return (
    <div className="browse">

{/* Find out why the video stutters so much, see if it make any difference being online? */}
{/* Try different bitrate : https://stackoverflow.com/questions/20753861/html5-video-seamless-looping or try to make the videos longer. Otherwise try some of these: https://mux.com/blog/mobile-safari-hls-bug-with-short-form-looping-videos/  Or just smaller size? seems to work better for the other videos*/}

      <SanityMuxPlayer
          assetDocument={postData[0].projectAssets[0].muxAssetDoc[0]}
          autoload={true}
          autoplay={true}
          showControls={false}
          // playsinline={true}
          // muted={true}
          loop={true}
          height={562}
          width={562}
      />
    </div>
  );
}

export default Video;