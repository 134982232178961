import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import BlockContent from "@sanity/block-content-to-react";

import SanityMuxPlayer from 'sanity-mux-player'
import MuxPlayer from "@mux/mux-player-react"; 
import "@mux/mux-player/themes/microvideo";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/navigation";
import {EffectFade, Navigation } from "swiper";

import { AiFillPlayCircle } from "react-icons/ai";

import sanityClient from "../../client.js";

import { animated, useSpring } from 'react-spring';

import './Browse.style.scss';

import Loader from '../../Components/Loader/Loader.component';

// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const VideoPlayer = ({videoAsset})=>{
  console.log(videoAsset);
  // const {cursorChangeHandler} = useContext(MouseContext);
  
  const [isPlaying, setIsPlaying] = useState(false)
  const video = useRef(null)

  const size = ()=>{
    let str = videoAsset.data.aspect_ratio.split(':');
    let ratio = (str[1] / str[0])
    let width;
    let height;
    if (str[0] > str[1]){ //portrait
      
      width = (window.innerHeight - 200) / ratio + 'px'
      height = (window.innerHeight - 200)
    }
    if (str[0] < str[1]){ //landscape
      width = (window.innerHeight - 200) / ratio + 'px'
      height = (window.innerHeight - 200)
    }
    if (str[0] == str[1]){ //square
      width = 'auto'
      height = 'auto'
    }
    return [width, height]
  }

  const videoClickHandler = ()=>{
    if (isPlaying){
      video.current.video.current.pause()
      setIsPlaying(false)
    }else{
      video.current.video.current.play()
      setIsPlaying(true)
    }
  }

  if (!videoAsset) return<></>
  return (
    <div className="video-player-wrapper"
    style={{width:size()[0],height:size()[1]}}
    >

      {/* <div
        className={`video-player-controls ${isPlaying ? 'hide-controls' :''}`}
        onClick={()=>videoClickHandler()}
      >
        <AiFillPlayCircle/>
      </div> */}

      <MuxPlayer
        thumbnailTime="0"
        theme="microvideo"
        streamType="on-demand"
        playbackId={videoAsset.playbackId}
        style={{width:size()[0],height:size()[1]}}
        // playing={isPlaying}
        // ref={video}
      />

      {/* <SanityMuxPlayer
        onClick={()=>videoClickHandler()}
        ref={video}
        assetDocument={videoAsset}
        autoload={true}
        className={'video-player'}
        loop={false}
        playsInline={true}
        muted={false}
        showControls={false}
        // playing={true}
        poster={`https://image.mux.com/${videoAsset.playbackId}/thumbnail.jpg?time=0&width=${window.innerWidth}`}
        // poster={true} // defaults to true, an URL can be provided to override the Mux asset thumbnail
        /> */}
    </div>
  )
}

const Browse = ({userSnapshot}) => {
  // console.log(userSnapshot);

  const isTabletOrMobileDevice = useMediaQuery({
      query: '(max-width: 1000px)'
    });

    const [swiperIndex, setSwiperIndex] = useState(0)

  const [postData, setPostData] = useState(null);

  const [currentSlideBrowseIndex, setCurrentSlideBrowseIndex] = useState(0);

  const [displayProjectInfoToggled,toggleDisplayProjectInfo] = useState(false);

  const [isPlaying, setIsPlaying] = useState(0)

  const navigationNextRef = useRef(null)
  const navigationPrevRef = useRef(null)

useEffect(() => {
  
  sanityClient
  .fetch(
    `*[_type == "visualProject"]{
        projectTitle,
        projectText,
        _id,
        slug,
        projectUrl,
        projectAssets[]{'isImage': isImage, "imageUrl": image.asset->url, 'muxAssetDoc': *[_id == ^.video.asset._ref]{...},
        }
      } | order(orderRank)
    `
  )
  .then((data) => setPostData(data))
  .catch(console.error);
    
}, []);

const handleClose = () =>{
  if(displayProjectInfoToggled) {toggleDisplayProjectInfo(false);}
}

  if (!postData) return <div></div>;
console.log(postData)
  return (
    <div className="browse">

        <div className="top">

        <div className="left container">
          <div
          className="linkLike"
          onClick={() => toggleDisplayProjectInfo(!displayProjectInfoToggled)}>
            {postData[swiperIndex].projectTitle}
          </div>
        </div>
        
        <div className="right container">
            <>{displayProjectInfoToggled ? <div className="linkLike" onClick={() => handleClose()}>Close</div> : '' }</>
        </div>
        </div>


      <div className={`project-info ${displayProjectInfoToggled ? 'project-info-display' : ''}`}>
        <BlockContent
        blocks={postData[swiperIndex].projectText}
        projectId={sanityClient.clientConfig.projectId}
        dataset={sanityClient.clientConfig.dataset}
        />
      </div>

      <div>
      <div
        ref={navigationNextRef}
        className="carouselButtonNext"
      >
        <div className="carouselButtonArrow">
          &rarr;
        </div>
      </div>
      <div
        ref={navigationPrevRef}
        // onClick={() => console.log('clo')}
        className="carouselButtonPrev"
      >
        <div className="carouselButtonArrow">
          &larr;
        </div>
      </div>
            <div className="carouselWrapper">
              <Swiper
                // onSwiper={setSwiperIndex()}
                
                onSlideChange={(e)=>setSwiperIndex(e.realIndex)}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                onBeforeInit={(swiper) => {
                      swiper.params.navigation.prevEl = navigationPrevRef.current;
                      swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                // navigation={true}
                modules={[EffectFade, Navigation]}
                slidesPerView={1}
                loop={true}
                spaceBetween={400}
                // effect={"fade"}
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                
                {postData.map((item, index) =>{
                  return(
                    <SwiperSlide key={index} className="multi-video-wrapper">
                      {isTabletOrMobileDevice ? 
                        <div className="single-video-wrapper" key={index}>
                          <VideoPlayer isPlaying={isPlaying} setIsPlaying={setIsPlaying} videoAsset={item.projectAssets[0].muxAssetDoc[0]}/>
                        </div>
                        :
                      <>
                        {item.projectAssets.map((item, index)=>{
                          return(
                            <div className="single-video-wrapper" key={index}>
                              <VideoPlayer isPlaying={isPlaying} setIsPlaying={setIsPlaying} videoAsset={item.muxAssetDoc[0]}/>
                            </div>
                          )
                        })}
                        </>
                      }
                      </SwiperSlide>
                  )
                  })}
                  </Swiper>
              </div> 
            <div className="loaderWrapper">
              {/* <Loader userSnapshot={userSnapshot} /> */}
            </div>
      </div>

      <div className="bottom">
          <div className="left container">
          </div>
          <div className="right container">
            <Link to={'/About'}>About</Link>
          </div>

      </div>
    </div>
  );
}

export default Browse;