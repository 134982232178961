import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, useParams } from "react-router-dom";
import { animated, useSpring, config } from 'react-spring';
import { useMediaQuery } from 'react-responsive';

import './App.scss';

import About from "./Pages/About/About.page.jsx";
import Browse from "./Pages/Browse/Browse.page.jsx";
import Frontpage from "./Pages/Frontpage/Frontpage.page.jsx";
import Photobooth from "./Pages/Photobooth/Photobooth.page.jsx";
import Video from "./Pages/Browse/Video.page.jsx";

// import userSnapshot from 'https://jonasersland.net/HOME/uploads/1624602143948.jpeg';

function App() {

  const [userSnapshot, setUserSnapshot] = useState(null);
  const [timeStamp, setTimeStamp] = useState(null);

  useEffect(() =>{
      // const filePath = '../../Server/filenames.json';
      fetch('https://www.jonasersland.net/HOME/filenames.json?v=' + (new Date()).getTime())
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setUserSnapshot(data.img);
        setTimeStamp(data.date)
      });
  
  
      // setTimeStamp('13.07');
      //setBackgroundImg(userSnapshot);
  

    // setUserSnapshot('https://jonasersland.net/HOME/uploads/1624602143948.jpeg');
  }, []);

  // useEffect(()=>{
  //   console.log(userImage)
  // },[setUserSnapshot])

  const fadePage = useSpring({
    opacity: 1,
  })

  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-width: 1000px)'
  });

  return (
    <div>
      <BrowserRouter>
            {/* <animated.div style={fadePage}> */}
              <Route render={()=> <Frontpage userSnapshot={userSnapshot} timeStamp={timeStamp} />} path="/" exact />
              <Route component={Photobooth} path="/Photobooth"/>
              {isTabletOrMobileDevice ?
              ''
              :
              <Route render={()=> <Browse userSnapshot={userSnapshot} />} path="/Browse"/>}
              <Route component={About} path="/About"/>
              <Route component={Video} path="/Video"/>
            {/* </animated.div> */}
      </BrowserRouter>
    </div>
  );
}
export default App;
